<template>
  <v-container>
    <div class="mx-auto pt-4 my-survey-wrapper">
      <v-card flat>
        <v-card-text class="pa-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <!-- Q1 -->
            <div class="survey-question">
              <div class="question">Q1. 商品をご購入いただいたショップを選択してください。</div>
              <v-radio-group v-model="form.i1" :rules="[rules.requiredSelect]">
                <v-radio
                  v-for="o in shops"
                  :key="'option-' + o.id"
                  :label="o.label"
                  :value="o.id"
                  :disabled="loading"
                ></v-radio>
              </v-radio-group>
            </div>
            <!-- Q1 -->

            <!-- Q2 -->
            <div class="survey-question">
              <div class="question">Q2. 注文番号を入力してください。</div>
              <div class="q-subtitle" v-if="selectedShop && selectedShop.hasOwnProperty('url')">
                ※注文番号は
                <a :href="selectedShop.url" target="_blank">{{selectedShop.label}} 購入履歴ページ</a>よりご確認いただけます。
              </div>
              <template v-if="selectedShop">
                <template v-if="selectedShop.hasOwnProperty('masks')">
                  <v-text-field
                    key="fa"
                    placeholder="注文番号"
                    :rules="[rules.required, rulesByMasks(selectedShop.masks)]"
                    v-mask="selectedShop.masks"
                    v-model="form.i2"
                    :loading="loading"
                    hint="記入例：123-4567890-1234567"
                  ></v-text-field>
                </template>
                <template v-else>
                  <v-text-field
                    key="fo"
                    placeholder="注文番号"
                    :rules="[rules.required]"
                    v-model="form.i2"
                    :loading="loading"
                    hint="記入例：123-4567890-1234567"
                  ></v-text-field>
                </template>
              </template>
              <template v-else>
                <v-text-field placeholder="注文番号" disabled hint="記入例：123-4567890-1234567"></v-text-field>
              </template>
            </div>
            <!-- Q2 -->
          </v-form>
        </v-card-text>
      </v-card>
      <div class="text-center pa-4">
        <v-btn
          large
          depressed
          class="white--text"
          :style="`background-color:${brand.color.brand}`"
          @click="clickedNext"
          :loading="loading"
        >
          <span class="title pl-4">次 へ</span>
          <v-icon class="pl-2">mdi-chevron-right</v-icon>
        </v-btn>       
      </div>
      <div class="text-left grey--text text--darken-3 pb-4 mb-4">
      <div class="mx-auto pt-4" style="max-width:600px;">
        <p class="grey--text body-2 pt-4">
          次の画面に進めない場合は、お手数ですが下記アドレスへ注文番号と共にお問い合わせ下さい。
          </p>
          <div class="text-center py-4">
            <v-icon class="grey--text pr-1 pb-1">mdi-email-outline</v-icon>
            <a :href="`mailto:${brand.emailAddress}`">
              <span class="title">{{brand.emailAddress}}</span>
            </a>
         </div>
        
        
      </div>
</div>
      
      
    </div>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import axios from "axios";
export default {
  directives: {
    mask
  },
  data: () => ({
    form: {
      i1: null,
      i2: ""
    },
    loading: false,
    rules: {
      required: value => !!value || "入力してください",
      requiredSelect: value => !!value || "選択してください",
      min: v => v.length >= 8 || "Min 8 characters",
      emailMatch: () => "The email and password you entered don't match"
    },
    valid: true
  }),
  methods: {
    rulesByMasks(masks) {
      const strs = masks.map(m => m.replace(/#/g, "\\d"));
      const rule = v => {
        return (
          strs.some(str => {
            return new RegExp(`^${str}$`, "g").test(v);
          }) || "正しい注文番号を入力してください"
        );
      };
      return rule;
    },
    clickedNext() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.checkOrderNumber();
      }
    },
    checkOrderNumber() {
      const orderNumber = this.form.i2;
      const callback = () => {
        this.goNext();
      };
      const finalCallback = () => {
        this.loading = false;
      };
      this.$store.dispatch("checkOrderNumber", {
        orderNumber,
        callback,
        finalCallback
      });
    },
    goNext() {
      const i1 = this.form.i1;
      const i2 = this.form.i2;
      this.$store.commit("saveForm", { i1, i2 });
      this.$store.commit("saveStep", 2);
      this.$router.push({ name: "step2" });
    }
  },
  computed: {
    selectedShop() {
      if (this.brand && this.form.i1) {
        return this.brand.shops.find(s => s.id == this.form.i1);
      }
      return null;
    },
    shops() {
      if (this.brand) {
        return this.brand.shops;
      }
      return [];
    },
    urlForOrderPageOfSelectedShop() {
      if (this.form.i1) {
        const target = this.shops.find(item => item.value == this.form.i1);
        if (target) {
          return target.url;
        }
      }
      return null;
    },
    brand() {
      return this.$store.getters.brandInfo;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.state.step < 1) {
        vm.$router.push({ name: "home" });
      } else if (vm.$store.state.step > 1) {
        vm.form.i1 = vm.$store.state.form.i1;
        vm.form.i2 = vm.$store.state.form.i2;
      }
    });
  }
};
</script>
