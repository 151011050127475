<template>
  <v-container>
    <div class="mx-auto pt-4 my-survey-wrapper">
      <v-card flat>
        <v-card-text class="pa-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <!-- i8 -->
            <div class="survey-question">
              <div class="question">Q{{sq + 1}}. ご購入いただいた商品の感想</div>
              <div class="q-subtitle">
                今後の商品開発のためにご購入いただきました商品のご感想をお聞きしております。
                皆様からの率直なご感想をお待ちしております。
              </div>
              <v-textarea
                v-model="form.i8"
                :rules="[rules.required, rules.min50, rules.max]"
                counter
                placeholder="商品のご感想をご記入ください"
                ref="review"
              ></v-textarea>
            </div>
            <!-- i8 -->

            <template v-if="showCheckReview">
              <!-- (レビューボタン) -->
              <div class="pb-5 mb-5">
                <div class="mx-auto pt-4" style="max-width:350px;">
                  <v-card flat color="primary" @click="clickedFeedback">
                    <v-card-title class="white--text body-1 text-center">
                      <v-spacer></v-spacer>
                      <div>
                        <div class="title">感想をコピーして投稿する</div>
                        <div class="body-2">（別タブで{{shopLabel}}へ遷移します）</div>
                      </div>
                      <v-icon class="pl-3 pr-3" color="white">mdi-content-copy</v-icon>
                    </v-card-title>
                  </v-card>
                </div>
              </div>
              <!-- (レビューボタン) -->

              <!-- i9 -->
              <div v-if="shop" class="survey-question">
                <div class="question">Q{{sq + 2}}. {{shopLabel}}への投稿</div>
                <div class="q-subtitle">{{shopLabel}}への投稿が完了しましたらチェックマークへのチェックをお願い致します。</div>
                <v-checkbox v-model="form.i9" :label="`${shopLabel}へのレビュー投稿が完了したことを確認します`"></v-checkbox>
              </div>
              <!-- i9 -->
            </template>

            <!-- i10 -->
            <div class="survey-question">
              <div class="question">Q{{sq + (showCheckReview ? 3: 2)}}. Eメールアドレス（正しいことを確認してください）</div>           
              <v-text-field
                :rules="[rules.required, rules.max]"
                v-model="form.i10"
                label
                placeholder="Eメールアドレスをご記入ください"
              ></v-text-field>
            </div>
            <!-- i10 -->
          </v-form>
        </v-card-text>
      </v-card>
      <div class="text-center pa-4">
        <v-btn
          large
          depressed
          class="white--text"
          :style="`background-color:${brand.color.brand}`"
          :loading="loading"
          @click="clickedNext"
        >
          <span class="title px-4">送 信</span>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import axios from "axios";
import moment from "moment";

export default {
  directives: {
    mask
  },
  data: () => ({
    form: {
      i8: null,
      i9: null,
      i10: null
    },
    rules: {
      required: value => !!value || "入力してください",
      requiredSelect: value => !!value || "選択してください",
      min: v => v.length >= 8 || "Min 8 characters",
      min50: v => !v || (v && v.length >= 50) || "最低50文字入力してください",
      max: v =>
        !v || (v && v.length <= 1024) || "1024文字以内で入力してください"
    },
    valid: true,
    loading: false
  }),
  methods: {
    clickedNext() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$nextTick(() => {
          this.goNext();
        });
      }
    },
    goNext() {
      const i8 = this.form.i8;
      const i9 = this.form.i9;
      const i10 = this.form.i10;
      this.$store.commit("saveForm", { i8, i9, i10 });
      this.$store.dispatch("sendForm", {
        callback: () => {
          this.loading = false;
          this.$store.commit("saveStep", 999);
          this.$router.push({ name: "complete" });
        },
        errorCallback: () => {
          this.loading = false;
          alert(
            "エラーが発生したため送信できませんでした。しばらく待ってから再度送信してください。"
          );
        }
      });
    },
    clickedFeedback() {
      if (this.shop) {
        const url = this.shopReviewUrl;
        const textarea = this.$refs.review.$refs.input;
        textarea.select();
        document.execCommand("copy");
        window.open(url, "_blank");
      }
    }
  },
  computed: {
    brand() {
      return this.$store.getters.brandInfo;
    },
    sq() {
      if (this.brand) {
        return this.brand.questions.length + 4;
      }
      return 4;
    },
    hasFullStars() {
      const thisStar = this.$store.state.form.i7;
      const requestReview = this.$store.getters.brandInfo.requestReview;
      if (
        thisStar != null &&
        requestReview &&
        requestReview.hasOwnProperty(thisStar)
      ) {
        const threshold = requestReview[thisStar];
        const randNumber = this.$store.state.randNumber;
        if (randNumber < threshold) {
          return true;
        }
      }
      return false;
    },
    shop() {
      const shopName = this.$store.state.form.i1;
      if (shopName) {
        return this.brand.shops.find(s => {
          return s.id == shopName;
        });
      }
      return null;
    },
    shopLabel() {
      if (this.shop) {
        if (this.shop.hasOwnProperty("reviewUrl")) {
          return this.shop.label;
        } else {
          return "Amazon";
        }
      }
      return null;
    },
    productInfo() {
      return this.$store.getters.productInfo;
    },
    shopReviewUrl() {
      if (this.shop) {
        if (this.shop.hasOwnProperty("reviewUrl")) {
          return this.shop.reviewUrl;
        } else {
          if (this.productInfo && this.productInfo.ASIN) {
            return `https://www.amazon.co.jp/review/create-review?asin=${this.productInfo.ASIN}`;
          } else {
            return "https://www.amazon.co.jp/review/create-review";
          }
        }
      }
      return null;
    },
    showCheckReview() {
      return this.hasFullStars && this.shopReviewUrl && this.shopLabel;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.state.step < 4) {
        vm.$router.push({ name: "home" });
      }
      vm.form.i8 = vm.$store.state.form.i8;
      vm.form.i9 = vm.$store.state.form.i9;
      vm.form.i10 = vm.$store.state.form.i10;
    });
  }
};
</script>
