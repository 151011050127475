<template>
  <div>
    <v-container class="text-center mt-4 pt-3">
      <v-icon color="success lighten-1" size="100">mdi-check-circle-outline</v-icon>
      <div class="headline pt-4">プレミアム保証の登録が完了しました</div>
    </v-container>
    
  </div>
</template>

<script>
export default {
  computed: {
    brand() {
      return this.$store.getters.brandInfo;
    },
    giftCard() {
      if (this.brand) {
        const type = this.$store.state.form.gift;
        if (type == "amazon_giftcard") {
          return "プレミアム保証(デジタル証明書)";
        } else if (type == "starbucks_giftcard") {
          return "スターバックスギフトカード";
        } else if (type == "quocard") {
          return "クオカード";
        }
      }
      return "ギフトカード";
    }
  }
};
</script>
